import { imageToHttps } from 'services/image_converter'

export const loadImg = url => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => resolve(img)
    img.onerror = reject
    img.src = url
  })
}

export const getImageDimensions = async url => {
  const defaultDimensions = { width: 0, height: 0 }
  if (!url || typeof url !== 'string') {
    return defaultDimensions
  }
  try {
    const img = await loadImg(url)
    return { width: img.width, height: img.height }
  } catch (error) {
    // If it gets here, it means we failed to load the image for some reason
    return defaultDimensions
  }
}

export const buildImage = imageUrl => imageToHttps(imageUrl)

// Some images define their resolution with something like `._SX38_SY50_CR,0,0,38,50_.`,
// which ended up with bad resolution. Increasing the image resolution and standardising it here
export const formatImageResolution = imageUrl => {
  return buildImage(imageUrl).replace(/(\._)\S+(_\.)/, '._SL300_.')
}

export const downloadImage = async url => {
  try {
    const response = await fetch(url, { mode: 'cors' })
    const blob = await response.blob()
    const urlObject = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = urlObject
    link.download = url.split('/').pop() // Extracts filename from URL
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    window.URL.revokeObjectURL(urlObject)
  } catch {
    /* Ignore */
  }
}
